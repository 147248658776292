<div class="booking-data relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="tableHeader"
            (cdkDragReleased)="true" (cdkDropListDropped)="dropCol($event)">
                @for ( item of tableHeader; track item.title) {
                <th scope="col" class="px-4 py-3 truncate" cdkDrag (mousedown)="mouseDown($event)" cdkDragLockAxis="x">
                    @if(item.display){
                    <span class="text-sm sm:text-base font-medium cursor-move">{{item.title}}</span>
                    }
                    @if(item.sort){
                    <i class='bx bxs-sort-alt cursor-pointer'></i>
                    }
                </th>
                }
            </tr>
        </thead>
        <tbody>
            @for (row of tableData; track row;let i = $index) {
                <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    @for (header of tableHeader; track header.title) {
                        <td class="text-sm px-2 py-2 sm:px-4 sm:py-3 truncate" [ngStyle]="{'color': handleStatusColor(header.status ? row[header.key] : '')}">

                            @if(isDate(row[header.key])) {
                                {{row[header.key] | date:'short'}}
                            }
                            <div class="flex gap-2">
                                @if (header.view) {
                                    <app-button  icon="bx bx-show" (action)="openConsole.emit(row)"></app-button>
    
                                }
                                @if (header.edit) {
                                    <app-button  icon="bx bx-edit" (action)="edit.emit(row)" class="ml-2"></app-button>
                                }
                                @if (header.delete) {
                                    <app-button  icon="bx bx-trash" (action)="delete.emit(row)" class="ml-2"></app-button>
                                }
                                @if(header.toggle){
                                <label class="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" [value]="row[header.key]"  class="sr-only peer" [checked]="row[header.key]" (change)="updateStatus.emit({$event, row})">
                                    <div class="relative w-11 h-6 bg-red-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-red-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-800"></div>
                                 </label>
                                }
                            </div>
                            @if (!isDate(row[header.key]) && !header.toggle) {
                                {{row[header.key]}}
                            }
                        </td>
                    }
                </tr>
            }
        </tbody>
               
    </table>
</div>