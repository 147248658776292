import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  
  /**
   *  status color
   * @param status 
   * @returns  
   */
  updateStatusColor(status: string) {
    if (!status) return '';
    let colorList:any = {
      Pending: '#FF8349',
      Approved: '#16a34a',
      Cancelled: '#FF7075',
      Completed: '#16a34a',
      Active: '#31C48D',
      InActive: '#E02424',
      Paid: '#16a34a',
      Unpaid: '#FF7075',
    }
      return colorList[status] || '';
    
  }
  }
