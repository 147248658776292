import { Directive, HostListener } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appSingleSpace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SingleSpaceDirective,
      multi: true,
    },
  ],
})
export class SingleSpaceDirective implements Validator {

  constructor() { } validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) return null; // Allow empty input

    // Check for valid format: only letters and at most one single space between words
    const isValid = /^[a-zA-Z]+( [a-zA-Z]+)?$/.test(value);
    
    return isValid ? null : { invalidLastName: true };
  }

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    // Replace multiple spaces with a single space and trim
    const newValue = input.value
      .replace(/\s+/g, ' ') // Replace multiple spaces with one space
      .trim(); // Trim leading and trailing spaces
    
    // If new value has multiple spaces, we can add a check for length of words
    const words = newValue.split(' ');
    if (words.length > 2) {
      // Join words with a single space if there are more than two
      input.value = words.slice(0, 1).join(' ');
    } else {
      input.value = newValue; // Set the cleaned value
    }
  }
}
