import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubAdminService {

  constructor(private http:HttpClient) { }
  
  /**
   * Creates sub admin detail
   * @param params 
   * @returns sub admin detail 
   */
  public createSubAdmin(params:any): Observable<any>{
    return this.http.post(`${environment.API_BASE_URL}admin/add-sub-admin`,params)
   }
   
   /**
    * Lists sub admin
    * @param page 
    * @param limit 
    * @param search 
    * @returns sub admin 
    */
   public listSubAdmin(page:number,limit:number,search:string): Observable<any>{ 
    return this.http.get(`${environment.API_BASE_URL}admin/sub-admin-list?page=${page}&limit=${limit}&search=${search}`);
   }
   
 /**
  * Deletes sub admin detail
  * @param id 
  * @returns sub admin detail 
  */
 public deleteSubAdmin(id:string): Observable<any>{
  return this.http.delete(`${environment.API_BASE_URL}admin/sub-admin/${id}`)
 }
 
  /**
   * Gets sub admin
   * @param id 
   * @returns sub admin 
   */
  public getSubAdmin(id:string): Observable<any>{
    return this.http.get(`${environment.API_BASE_URL}admin/sub-admin/${id}`)  
  
   }

   /**
    * Updates sub admin
    * @param id 
    * @param params 
    * @returns sub admin 
    */
   public updateSubAdmin(id:string,params:any): Observable<any>{
    return this.http.patch(`${environment.API_BASE_URL}admin/update-sub-admin/${id}`,params)
   }
   
}
