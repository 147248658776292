import { DragDropModule } from "@angular/cdk/drag-drop";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";

  @NgModule({
    imports: [
   ReactiveFormsModule,
   DragDropModule,
   MatSnackBarModule
    ],
    exports: [
      ReactiveFormsModule,
      DragDropModule,
      MatSnackBarModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    declarations: [],
  })
  export class MatModule { }