import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoSpaces]'
})
export class NoSpacesDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // If the key is a space, prevent default behavior (disallow the space key)
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    // Get pasted data
    const pastedData = event.clipboardData?.getData('text') || '';
    // If pasted data contains spaces, prevent the paste
    if (pastedData.includes(' ')) {
      event.preventDefault();
    }
  }
}
