import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() label = '';
  @Input() bgColor = '#1b3faa';
  @Input() color = '#ffff';
  @Input() icon = '';
  @Output() action = new EventEmitter();
  @Input() disabled: boolean = false;
  @Input() loader: boolean = false;
}
