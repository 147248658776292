import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrl: './delete.component.scss'
})
export class DeleteComponent {
  @Output() confirmDelete = new EventEmitter<void>();
  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) { }

    onNoClick(): void {
      this.dialogRef.close();
    }
    
    /**
     * Determines whether confirm click on
     */
    onConfirmClick(): void {
      this.confirmDelete.emit(); // Emit the event on confirmation
      this.dialogRef.close(); // Close the dialog
    }
}
