import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-travel-guru-table',
  templateUrl: './travel-guru-table.component.html',
  styleUrl: './travel-guru-table.component.scss'
})
export class TravelGuruTableComponent {
  @Input()tableHeader:any;
  @Input()tableData:any;
  @Output() action = new EventEmitter();
  @Output() openConsole = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() status = new EventEmitter();
  @Output() updateStatus = new EventEmitter();
  constructor(private commonService:CommonService) { }
  /**
   * Checks if the given value is a Date object or a string that can be parsed into a Date.
   *
   * @param {any} value - The value to check.
   * @return {boolean} True if the value is a Date or a parsable date string, false otherwise.
   */
  isDate(value: any): any {
    if (value instanceof Date && !isNaN(value.valueOf())) {
    return value; // Return the Date object
   } 
  else if (typeof value === 'number' && !isNaN(value)) {
    return value; // Return the number
   } 
   else if (typeof value === 'string') {
    const parsedDate = Date.parse(value);
    if (!isNaN(parsedDate)) {
      return new Date(parsedDate); // Return as a Date object
    }
  }
  }

/**
 * Returns a color code based on the given status.
 *
 * @param {string | Date | boolean} status - The status to get the color code for.
 * @return {string} The color code for the given status.
 */
handleStatusColor(status: string) {

  if (!status) return '';
  
    return this.commonService.updateStatusColor(status) || '';
  
}
openDrawer(data: any) {
  this.action.emit(data);
}
/**
 * Handles the drop event of a column in the table header.
 *
 * @param {CdkDragDrop<string[]> | any} event - The drop event object.
 * @return {void}
 */
dropCol(event: CdkDragDrop<string[]> | any) {
  moveItemInArray(
    this.tableHeader,
    event.previousIndex,
    event.currentIndex
  );
}
/**
 * Handles the mouse down event to calculate the position of an element.
 *
 * @param {any} event - The mouse down event object.
 * @param {any} [el=null] - The target element of the event.
 * @return {void}
 */
mouseDown(event: any, el: any = null) {
  el = el || event.target;
  const pos = {
    x: el.getBoundingClientRect().left - event.clientX + 'px',
    y: el.getBoundingClientRect().top - event.clientY + 'px',
    width: el.getBoundingClientReopenConsolect().width + 'px',
  };
}

};
