import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './components/button/button.component';
import { FilterComponent } from './components/filter/filter.component';
import { SearchComponent } from './components/search/search.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NoSpacesDirective } from './directives/no-spaces.directive';
import { DeleteComponent } from './components/delete/delete.component';

import { MatModule } from './modules/mat-module';
import { TravelGuruTableComponent } from './components/travel-guru-table/travel-guru-table.component';
import { SingleSpaceDirective } from './directives/single-space.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NumberInputDirective } from './directives/number-input.directive';


@NgModule({
  declarations: [
    TravelGuruTableComponent,
    SearchComponent,
    ButtonComponent,
    FilterComponent,
    ClickOutsideDirective,
    ToasterComponent,
    NoSpacesDirective,
    DeleteComponent,
    BreadcrumbsComponent,
    SingleSpaceDirective,
    PaginationComponent,
    NumberInputDirective,
  
  ],
  imports: [
    CommonModule,
    MatModule
   
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  exports:[TravelGuruTableComponent, SearchComponent, ButtonComponent, MatModule, FilterComponent, ClickOutsideDirective, NoSpacesDirective, BreadcrumbsComponent,PaginationComponent]
})
export class SharedModule { }
