<div class="filter-container">
  <div class="mt-4">
    <form [formGroup]="filterForm">
    @if (enumFilter.Booking==this.type) {
      <div class="mb-4 flex">
      <div class="mr-4">
        <p>From</p>
        <input type="date" id="from" formControlName="fromDate" [max]="maxDate" (change)="onFromDateChange()" class="date-range text-xs sm:text-sm text-gray-700" name="from">
      </div>
      <div class="mb-4">
        <p>To</p>
        <input type="date" id="to" formControlName="toDate" [max]="maxDate" (change)="onFromDateChange()" class="date-range text-xs sm:text-sm text-gray-700" name="to">
      </div>
      </div>
      <div class="mb-4">
  
        <label for="destination" class="block mb-2 text-xs sm:text-sm font-medium text-gray-900 dark:text-white">Destination</label>
        <select id="destination" formControlName="destination" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" disabled selected>Choose a destination</option>
          @for(destination of detinationData;track destination) {
          <option [value]="destination || ''">{{destination}}</option>>
          }
        </select>
    
      </div>
      <div class="mb-4">
        <label for="bookingStatus" class="block mb-2 text-xs sm:text-sm font-medium text-gray-900 dark:text-white">Booking status</label>
        <select id="bookingStatus" formControlName="booking_status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" disabled selected>Choose a booking status</option>
          <option value="Completed">Completed</option>
          <option value="Pending">Pending</option>
          <!-- <option value="Cancelled">Cancelled</option>
          <option value="Refunded">Refunded</option> -->
        </select>
      </div>
    }

    @if(enumFilter.User==this.type){
      <div class="mb-4">
        <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
        <select id="status" formControlName="status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" selected disabled>Choose a status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      <!-- <div class="mb-4">
        <label for="emailVerified" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Verified</label>
        <select id="emailVerified" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose an email Verified</option>
          <option value="0">Verified</option>
          <option value="1">Unverified</option>
        </select>
      </div> -->
    }
    
   
    @if (enumFilter.Payment==this.type) {
      <label for="paymentDate" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment Date</label>
      <div class="mb-4 flex">
        <div class="mr-4">
          <p>From</p>
          <input type="date" id="from" formControlName="from_date" [max]="maxDate" (change)="onFromDateChange()" class="date-range text-xs sm:text-sm text-gray-700" name="from">
        </div>
        <div class="mb-4">
          <p>To</p>
          <input type="date" id="to" formControlName="to_date" [max]="maxDate" (change)="onFromDateChange()"class="date-range text-xs sm:text-sm text-gray-700" name="to">
        </div>
        </div>
      <div class="mb-4">
        <label for="paymentMethod" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment type</label>
        <select id="paymentMethod" formControlName="payment_method" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value=""  disabled selected>Choose an payment type</option>
          <option value="Debit">Debit</option>
          <option value="Credit">Credit</option>
          <option value="Upi">Upi</option>
          <option value="card">Card</option>
        </select>
      </div>
   
      <div class="mb-4">
        <label for="paymentStatus" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Payment status</label>
        <select id="paymentStatus" formControlName="payment_status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="" disabled selected>Choose a payment status</option>
          <option value="Paid">Paid</option>
          <option value="Unpaid">Unpaid</option>
        </select>
      </div>
    }
  </form>
    <div class="flex justify-end mt-3"> 
      <app-button label="Close" bgColor="#f9fafb" color="black" (action)="closeFilter.emit()"></app-button>
      <app-button label="Apply" class="ml-2" (action)="applyFilter.emit()"></app-button>
    </div>
 