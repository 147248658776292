import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss'
})
export class ToasterComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<ToasterComponent>) { }
  /**
   *  Dismisses the currently displayed toaster notification.
   * 
   *  @return {void} No return value.
   */
  closeToaster(): void {
    this.snackBarRef.dismiss();
  }
}
