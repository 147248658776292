import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http:HttpClient) { }
  
  /**
   * Payments list
   * @param page 
   * @param limit 
   * @param search 
   * @returns list 
   */
  public paymentList(page:number,limit:number,search?:string,filter?:string): Observable<any>{ 
    return this.http.get(`${environment.API_BASE_URL}admin/payment-list?page=${page}&limit=${limit}&search=${search? search :''}${filter ? filter : ''}`);
  }
  
  /**
   * Gets payment
   * @param id 
   * @returns payment 
   */
  public getPayment(id:string): Observable<any>{
    return this.http.get(`${environment.API_BASE_URL}admin/payment-details?id=${id}`)
  }
}
