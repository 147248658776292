import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterType } from 'src/app/shared/enums/filterType.enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {
  @Output() closeFilter = new EventEmitter();
  @Input() type : string =''
  enumFilter = FilterType
  @Input() filterForm: any;
  @Output() applyFilter = new EventEmitter();
  @Input() detinationData:any;
  minDate: string;
  maxDate: string;
 constructor() { 
  const today = new Date();
  this.minDate = today.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  this.maxDate = this.minDate;
  }
  
  /**
   * Determines whether from date change on
   */
  onFromDateChange() {
    const fromDateValue = this.filterForm.get('fromDate').value;
    if (fromDateValue) {
        // Reset maxDate to today
        this.maxDate = this.minDate; 
    } 

}

}